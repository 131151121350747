import React from 'react'

const Breadcrum = () => {
  return (
    <div className='container'>
      <span style={{ color: '#bebebe', fontSize: '10px' }}>
        Home / India / Agartala Restaurants
      </span>
    </div>
  )
}

export default Breadcrum

import React from 'react'
import { Container } from 'react-bootstrap'

const Heading = () => {
  return (
    <Container style={{ marginTop: '80px' }}>
      <h3>Agartala Restaurants</h3>
    </Container>
  )
}

export default Heading
